// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBZlpDB5gCG_SKq6WGNZ14dRSr-dm6igyA",
    authDomain: "webeer-c8161.firebaseapp.com",
    databaseURL: "https://webeer-c8161.firebaseio.com",
    projectId: "webeer-c8161",
    storageBucket: "webeer-c8161.appspot.com",
    messagingSenderId: "210665504590",
    appId: "1:210665504590:web:f5becdd627652dda90a81f",
    measurementId: "G-41ZNM9E8QN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
